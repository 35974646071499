<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Search -->
    <b-dropdown
      size="sm"
      id="kt_quick_search_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTSearchDefault></KTSearchDefault>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Search -->

    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Quick Actions -->

    <!--begin: My Cart -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownMyCart></KTDropdownMyCart>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: My Cart -->

    <!--begin: Quick panel toggle -->
    <!--end: Quick panel toggle -->

    <!--end: Language bar -->
    <b-row>
      <b-col class="d-flex align-items-center mr-4">
        <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <img
            src="../../../assets/image/images/notifications.svg"
            alt="bell-notif"
            style="width:80%;cursor:pointer;"
          />
          <b-badge v-if="listNotif.length > 0" variant="danger" style="padding: 4px;font-size: 8px;border-radius: 50%;position: absolute;right: -8px;top: -4px;border: 1px solid black;">{{ listNotif.length}}</b-badge>
        </template>
        <b-dropdown-header id="dropdown-header-label">
          <span class="font-weight-bold">Notifikasi <b-badge pill variant="danger" v-if="listNotif.length > 0">{{ listNotif.length}} Notif Baru</b-badge></span>
        </b-dropdown-header>
        <b-dropdown-item v-if="listNotif.length == 0">
            Belum ada Notifikasi
        </b-dropdown-item>
        <b-dropdown-item href="#" v-for="(item, index) in listNotif" :key="index" @click="toDetail(item)">
          <b-row>
            <b-col cols="9">
              <p class="pb-0 mb-0">{{ item.title }}</p>
              <small class="text-muted">{{ item.created_at}} Menit yang lalu</small>
            </b-col>
            <b-col cols="3" class="text-right">
              <b-button variant="success" size="sm">
                >> Detail
              </b-button>
            </b-col>
          </b-row>
        </b-dropdown-item>
      </b-dropdown>
      </b-col>
    </b-row>
    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }

  .dropdown-menu.show{
    position: absolute;
    transform: translate3d(0px, 28px, 0px);top: 5px;left: -229px;will-change: transform;width: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>

<script>
import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      listNotif: [],
      intervalGet : null,
    };
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    KTDropdownQuickAction,
    KTDropdownMyCart,
    KTQuickUser,
  },
  mounted(){
    this.getNotifList()
    // clearInterval()
    // setInterval(() => {
    //   this.getNotifList()  
    // }, 5000);
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    getNotifList(){
      this.$store
      .dispatch("getNotifikasi")
      .then((res) => {
        this.$nextTick(() => {
          var dat = res.data.data
          for(var i = 0; i < dat.length; i++){
            var fdupl =this.listNotif.find(el => {
              return el.id == dat[i].id
            })
            if(fdupl){
              return false
            } else {
              if(!dat[i].is_read) {
                this.listNotif.push(dat[i])
              }
            }
          }
        })
      })
    },
    toDetail(item){
      this.$store
      .dispatch("getNotifikasiDetail", item.id)
      .then(() => {
        if(item.tipe_notifikasi == 'pelaporan-kegiatan'){
          this.$router.push('/info-ormas/domisili')
        } else if(item.tipe_notifikasi == 'permohonan-perubahan'){
          this.$router.push('/tata-kelola/permohonan')
        }else {
          alert('terjadi kesalahan')
        }
      })
    },
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  },
  watch:{
    $route(){
      this.getNotifList()
    }
  }
};
</script>
