var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"15px"}},[(_vm.$route.name == 'formormas')?_c('ul',{staticClass:"menu-nav",staticStyle:{"padding-bottom":"0px !important","padding-top":"0px !important"}},[_c('router-link',{attrs:{"to":'/formdataormas'},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Layers.svg"}})],1),_c('span',{staticClass:"menu-text pl-3 font-weight-bolder"},[_vm._v("Pengisian Data Ormas")])])])]}}],null,false,498774836)})],1):_vm._l((_vm.listMenu),function(item,index){return _c('ul',{key:index,staticClass:"menu-nav",staticStyle:{"padding-bottom":"0px !important","padding-top":"0px !important"}},[(item.items.length == 0)?_c('router-link',{attrs:{"to":item.url},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":function($event){return _vm.collapseFalse()}}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Layers.svg"}})],1),_c('span',{staticClass:"menu-text pl-3 font-weight-bolder"},[_vm._v(_vm._s(item.label))])])])]}}],null,true)}):_c('li',{class:item.collapse ? 'menu-item menu-item-submenu menu-item-open' : 'menu-item menu-item-submenu',attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.collapseFunc(index)}}},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-4-blocks.svg"}})],1),_c('span',{staticClass:"menu-text pl-3 font-weight-bolder"},[_vm._v(_vm._s(item.label))]),(!item.collapse)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_vm._e(),(item.collapse)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_vm._e()],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.label))])])]),_vm._l((item.items),function(submenu,index2){return _c('router-link',{key:index2,attrs:{"to":submenu.url},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(submenu.items.length == 0)?_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-lg mt-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Flatten.svg"}})],1),_c('span',{staticClass:"menu-text pl-3 font-weight-bolder"},[_vm._v(_vm._s(submenu.label))])])]):_c('li',{class:submenu.collapse ? 'menu-item menu-item-submenu menu-item-open' : 'menu-item menu-item-submenu',attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.collapseFunc2(index,index2)}}},[_c('span',{staticClass:"svg-icon svg-icon-lg mt-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Flatten.svg"}})],1),_c('span',{staticClass:"menu-text pl-3 font-weight-bolder"},[_vm._v(_vm._s(submenu.label))]),(!submenu.collapse)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_vm._e(),(submenu.collapse)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_vm._e()],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(submenu.label))])])]),_vm._l((submenu.items),function(subsubmenu,index){return _c('router-link',{key:index,attrs:{"to":subsubmenu.url},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                                isActive && 'menu-item-active',
                                isExactActive && 'menu-item-active'
                              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-lg mt-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Mask.svg"}})],1),_c('span',{staticClass:"menu-text pl-3 font-weight-bolder"},[_vm._v(_vm._s(subsubmenu.label))])])])]}}],null,true)})})],2)])])]}}],null,true)})})],2)])])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }