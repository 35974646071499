<template>
  <div
    class="topbar-item dropdown"
    style="
    background-color: #4D4D4D;
    border-radius: 15px;"
  >
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
        style="color: #fff !important;"
      >
        {{ username }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <b-avatar v-if="user.profilUser.file_foto != null" alt="Pic" :src="fileURL + '/' + user.profilUser.file_foto"></b-avatar>
        <b-avatar v-else></b-avatar>
      </span>
    </div>

    <div class="dropdown-content">
      <div class="menu-item" style="height: 30px;margin:12px;">
        <button class="menu-link" @click="toSeeProfile()">
          <span class="menu-icon" style="margin-left: 15px;margin-right: 5px;">
            <!--begin::Svg Icon | path: icons/duotune/art/art002.svg-->
            <span class="svg-icon svg-icon-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 6.75C12 7.54565 11.6839 8.30871 11.1213 8.87132C10.5587 9.43393 9.79565 9.75 9 9.75C8.20435 9.75 7.44129 9.43393 6.87868 8.87132C6.31607 8.30871
                  6 7.54565 6 6.75C6 5.95435 6.31607 5.19129 6.87868 4.62868C7.44129 4.06607 8.20435 3.75 9 3.75C9.79565 
                  3.75 10.5587 4.06607 11.1213 4.62868C11.6839 5.19129 12 5.95435 12 6.75ZM10.5 6.75C10.5 7.14782 10.342 7.52936
                    10.0607 7.81066C9.77936 8.09196 9.39782 8.25 9 8.25C8.60218 8.25 8.22064 8.09196 7.93934 7.81066C7.65804 7.52936 7.5
                    7.14782 7.5 6.75C7.5 6.35218 7.65804 5.97064 7.93934 5.68934C8.22064 5.40804 8.60218 5.25 9 5.25C9.39782 5.25 9.77936 5.40804 10.0607 5.68934C10.342 5.97064 10.5 6.35218 10.5 6.75Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9 0.75C4.44375 0.75 0.75 4.44375 0.75 9C0.749999 13.5562 4.44375 17.25 9 17.25C13.5562 17.25 17.25 13.5563 17.25 
                  9C17.25 4.44375 13.5562 0.750001 9 0.75ZM2.25 9C2.25 10.5675 2.78475 12.0105 3.681 13.1565C4.31042 12.3299 5.12242 
                  11.6601 6.05357 11.1992C6.98472 10.7384 8.00981 10.4991 9.04875 10.5C10.0742 10.499 11.0864 10.7321 12.0082 11.1816C12.93 11.631 
                  13.737 12.2849 14.3677 13.0935C15.0176 12.2412 15.4551 11.2464 15.6442 10.1914C15.8332 9.13647 15.7683 8.05165 15.4549 7.02674C15.1414 6.00183 14.5884 5.06629 13.8416 4.29753C13.0948 3.52877 12.1757 2.9489 11.1603 2.60588C10.1449 2.26287 9.0624 2.16658 8.00239 2.32498C6.94239 2.48338 5.93534 2.89192 5.06458 3.51679C4.19381 4.14166 3.48436 
                  4.9649 2.99492 5.9184C2.50549 6.87189 2.25014 7.92823 2.25 9ZM9 15.75C7.45046 15.7523 5.9477 15.2193 4.746 14.241C5.2297 13.5486 5.8735 12.9832 6.62264 12.593C7.37179 12.2028 8.20409 11.9994 9.04875 12C9.88287 11.9993 10.7051 12.1977 11.4472 12.5786C12.1893 12.9595 12.8298 13.5119 13.3155 14.19C12.1045 15.2 10.5769 15.7522 9 15.75Z"
                  fill="white"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </span>
          <span
            class="menu-title"
            style="color: #fff;font-weight: 600;font-family: Open Sans;font-style: normal;margin-left: 10px;"
            >Profil User</span
          >
        </button>
      </div>
      <div class="menu-item" style="height: 30px;;margin:12px;">
        <button class="menu-link" @click="$bvModal.show('bv-modal-gantipass')">
          <span class="menu-icon" style="margin-left: 15px;margin-right: 5px;">
            <!--begin::Svg Icon | path: icons/duotune/art/art002.svg-->
            <span class="svg-icon svg-icon-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.125 6.1875C1.12482 6.97804 1.30982 7.75763 1.66517 8.4638C2.02053 9.16997 2.53636 9.78308 3.17133 10.254C3.8063 10.7249 4.54275 11.0406 5.32166 11.1756C6.10058 11.3107
                  6.9003 11.2614 7.65675 11.0318L13.5 16.875H16.875V13.5L11.0317 7.65675C11.2431 6.96043 11.3019 6.22673 11.204 5.50564C11.1062 
                  4.78456 10.854 4.09304 10.4648 3.47822C10.0755 2.8634 9.55827 2.33972 8.94831 1.94288C8.33835 1.54604 7.65 1.28535 6.93019 1.1786C6.21037 1.07184 5.47599 1.12153 4.77711 1.32426C4.07823 1.52699 3.43127 1.87801 2.88032 2.35338C2.32936 2.82876
                   1.88737 3.41733 1.58446 4.07898C1.28154 4.74063 1.12483 5.45981 1.125 6.1875ZM10.125 6.1875C10.1248 6.57477 10.0675 6.9599 9.95512 
                  7.3305L9.75938 7.97569L10.2358 8.45212L12.0251 10.2414L11.25 11.0171L12.0454 11.8125L12.8211 11.0368L13.7132 11.9289L12.9375 12.7046L13.7329 13.5L14.5086 12.7243L15.75 13.9657V15.75H13.9657L8.45213 10.2364L7.97569 9.75938L7.3305 9.95512C6.53793 10.1955 5.68973 10.1799 4.90656 9.91049C4.12338 9.64105 3.4451
                   9.13151 2.96821 8.45435C2.49133 7.7772 2.24011 6.9669 2.2503 6.13873C2.26049 5.31057 2.53156 4.5067 
                  3.02497 3.84148C3.51837 3.17626 4.20897 2.68356 4.99854 2.43347C5.78811 2.18338 6.63644 2.18863 7.42285 2.44848C8.20927 2.70833 8.89372 3.20955 9.37884 3.88083C9.86397 4.55211 10.1251 5.35927 10.125 6.1875Z"
                  fill="white"
                />
                <path
                  d="M6.75 5.625C6.75 5.00368 6.24632 4.5 5.625 4.5C5.00368 4.5 4.5 5.00368 4.5 5.625C4.5 6.24632 5.00368 6.75 5.625 6.75C6.24632 6.75 6.75 6.24632 6.75 5.625Z"
                  fill="white"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </span>
          <span
            class="menu-title"
            style="color: #fff;font-weight: 600;font-family: Open Sans;font-style: normal;margin-left: 10px;"
            >Ganti Kata Sandi</span
          >
        </button>
      </div>
            <div class="menu-item" style="height: 30px;;margin:12px;">
        <button class="menu-link" @click="logout()">
          <span class="menu-icon" style="margin-left: 15px;margin-right: 5px;">
            <!--begin::Svg Icon | path: icons/duotune/art/art002.svg-->
            <span class="svg-icon svg-icon-2">
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12.5L4.5 12.5L4.5 14L13.5 14L13.5 2L4.5 2L4.5 3.5L3 3.5L3 1.25C3 1.05109 3.07902 0.860322 
              3.21967 0.71967C3.36032 0.579018 3.55109 0.5 3.75 0.5L14.25 0.500001C14.4489 0.500001 14.6397 0.579018 
              14.7803 0.71967C14.921 0.860323 15 1.05109 15 1.25L15 14.75C15 14.9489 14.921 15.1397 14.7803 15.2803C14.6397 
              15.421 14.4489 15.5 14.25 15.5L3.75 15.5C3.55109 15.5 3.36032 15.421 3.21967 15.2803C3.07902 15.1397 3 14.9489 3 
              14.75L3 12.5ZM4.5 7.25L9.75 7.25L9.75 8.75L4.5 8.75L4.5 11L0.75 8L4.5 5L4.5 7.25Z" fill="white"/>
            </svg>
            </span>
            <!--end::Svg Icon-->
          </span>
          <span
            class="menu-title"
            style="color: #fff;font-weight: 600;font-family: Open Sans;font-style: normal;margin-left: 10px;"
            >Keluar</span
          >
        </button>
      </div>
    </div>
    <!-- <b-modal id="bv-modal-example" hide-footer hide-header>
      <h2
        class="text-center font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-12"
      >
        Profil User
      </h2>
      <b-form id="form" @submit="onSubmit">
        <b-row class="mb-1">
          <b-col cols="4" style="font-width">Nama Pendaftar</b-col>
          <b-col cols="6">: {{ user.nama_lengkap}}</b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="4" style="font-width">NIK Pendaftar</b-col>
          <b-col cols="6">: {{ user.identitas }}</b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="4">Email Pendaftar</b-col>
          <b-col cols="6">: {{ user.email }}</b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="4">No. HP Pendaftar</b-col>
          <b-col cols="6">: {{ user.no_telp }}</b-col>
        </b-row>
        <div class="text-center">
          <b-button
            @click="$bvModal.show('bv-modal-password')"
            class="button-aktivasi"
            >Ubah Profil</b-button>
        </div>
      </b-form>
    </b-modal> -->
    <b-modal
        id="bv-modal-example"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-footer
        >
        <template #modal-title> <div style="font-size:24px; font-weight:600;">{{createMode ? 'Profil User' : 'Ubah Profil'}} </div></template>
          <div class="d-flex justify-content-center align-items-center" style="width:100%;">
            <div style="position:relative;cursor:pointer;width: 207px;height: 200px;border-radius: 10px;display:flex;flex-flow:column; align-items:center;justify-content:center;">
            <img v-if="createMode"
                    :src="fileURL + '/' + user.profilUser.file_foto"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px;max-height:200px;"
                    alt="abstract"
                    @error="replaceByDefault"
                  />
                  <img v-if="!createMode"
                    :src="fileURL + '/' + form.foto"
                    style="width: 80%; margin-bottom: 20px; margin-left: 8px;max-height:200px;"
                    alt="abstract"
                    @error="replaceByDefault"
                  />
            <!-- <img v-if="user.profilUser.file_foto === null"
                src="/assets/images/default-thumbnail.jpg"
                class="round-image-dash" style="width: 80%; margin-bottom: 20px; margin-left: 8px;"
              > -->
                  <b-button v-if="!createMode" :disabled="loadUpload" variant="white" class="shadow" style="padding:0.5rem;position:absolute;bottom:15px;" @click="fileClicked()">
                    <v-icon>mdi-camera-plus-outline</v-icon>
                  </b-button>
                  <input style="display: none;" @change="uploadFile" type="file" :accept="'image/jpg,image/jpeg,image/png'" ref="fileInput"/>
            </div>
          </div>
          <div class="mt-5" v-if="!createMode">
            <b-row class="mb-3 d-flex align-items-center">
              <b-col cols="4" style="font-width">Nama Pengguna</b-col>
              <b-col cols="6" class="d-flex align-items-center">: <b-form-input readonly v-model="form.nama_pengguna" placeholder="Masukan Nama Pengguna"></b-form-input></b-col>
            </b-row>
            <b-row class="mb-3 d-flex align-items-center">
              <b-col cols="4" style="font-width">{{userType == 'ormas' ? 'No. SKT' : 'NIP'}}</b-col>
              <b-col cols="6" class="d-flex align-items-center">: <b-form-input readonly v-model="form.nip" placeholder="Masukan NIP"></b-form-input></b-col>
            </b-row>
            <b-row class="mb-3 d-flex align-items-center">
              <b-col cols="4" style="font-width">NIK</b-col>
              <b-col cols="6" class="d-flex align-items-center">: <b-form-input readonly v-model="form.nik" placeholder="Masukan NIK"></b-form-input></b-col>
            </b-row>
            <b-row class="mb-3 d-flex align-items-center">
              <b-col cols="4">Email Pengguna</b-col>
              <b-col cols="6" class="d-flex align-items-center">: <b-form-input v-model="form.email" placeholder="Masukan Email"></b-form-input></b-col>
            </b-row>
            <b-row class="mb-3 d-flex align-items-center">
              <b-col cols="4">No. HP</b-col>
              <b-col cols="6" class="d-flex align-items-center">: <b-form-input v-model="form.no_hp" placeholder="Masukan Email"></b-form-input></b-col>
            </b-row>
            <b-row class="mb-3 d-flex align-items-center" v-if="userType != 'ormas'">
              <b-col cols="4">Instansi</b-col>
              <b-col cols="6" class="d-flex align-items-center">:
                <model-select :options="options"
                :isDisabled="true"
                    v-model="form.instansi"
                    placeholder="select item">
                </model-select></b-col>
            </b-row>
          </div>
          <div v-if="createMode && user">
            <b-row class="mb-1">
              <b-col cols="4" style="font-width">Nama Pengguna</b-col>
              <b-col cols="6">: {{ user.profilUser.nama_lengkap}}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4" style="font-width">{{userType == 'ormas' ? 'No. SKT' : 'NIP'}}</b-col>
              <b-col cols="6">: {{ user.username }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4" style="font-width">NIK</b-col>
              <b-col cols="6">: {{ user.profilUser.identitas }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4">Email Pengguna</b-col>
              <b-col cols="6">: {{ user.profilUser.email }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4">No. HP</b-col>
              <b-col cols="6">: {{ user.profilUser.no_telp }}</b-col>
            </b-row>
            <b-row class="mb-1" v-if="userType != 'ormas'">
              <b-col cols="4">Instansi</b-col>
              <b-col cols="6">: {{ user.profilUser.masterKesbangpol ? user.profilUser.masterKesbangpol.nama : user.profilUser.m_kesbangpol_id }}</b-col>
            </b-row>
          </div>
          <div class="text-center" v-if="createMode">
            <b-button
              @click="toChangeProfile()"
              class="button-aktivasi"
              >Ubah Profil</b-button>
          </div>
          <div class="text-center" v-if="!createMode">
            <b-button
              variant="outline-primary"
              class="mt-16 text-center mr-5"
              @click="createMode = true"
            >
              Kembali
            </b-button>
            <b-button
              variant="primary"
              class="mt-16 text-center"
              @click="changeProfile()"
            >
              Ubah
            </b-button>
          </div>
      </b-modal>
    <b-modal id="bv-modal-password" hide-footer hide-header>
      <h2
        class="text-center font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-12"
      >
        Ubah Profil User
      </h2>
      <b-form id="form">
        <b-form-group id="input-group-sktAktif">
          <template v-slot:label>
            Nama Pendaftar
          </template>
          <b-form-input
            placeholder="Silahkan masukan Nama Pendaftar"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-noRegistrasi">
          <template v-slot:label>
            Email Pendaftar
          </template>
          <b-form-input
            placeholder="Silahkan masukan Email Terdaftar"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-noRegistrasi">
          <template v-slot:label>
            No. HP Pendaftar
          </template>
          <b-form-input
            placeholder="Silahkan masukan Nomor HP terdaftar"
          ></b-form-input>
        </b-form-group>
        <div class="text-center">
          <button
            class="button-back"
            size="lg"
            @click="$bvModal.hide('bv-modal-password')"
          >
            Kembali
          </button>
          <button
            class="ml-8 button-submit"
            size="lg"
            ref="kt_login_signin_submit"
          >
            Ubah
          </button>
        </div>
      </b-form>
    </b-modal>
    <b-modal id="bv-modal-gantipass" hide-footer hide-header>
      <h2
        class="text-center font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-12"
      >
        Ganti Kata sandi
      </h2>
      <b-form id="form">
        <b-form-group id="input-group-sktAktif">
          <template v-slot:label>
            Kata Sandi Lama
          </template>
          <b-form-input
          type="password"
          v-model="pass.oldPass"
            placeholder="Silahkan masukan kata sandi lama"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-noRegistrasi">
          <template v-slot:label>
            Kata Sandi Baru
          </template>
          <b-form-input
          type="password"
          v-model="pass.newPass"
            placeholder="Silahkan masukan kata sandi Baru"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-noRegistrasi">
          <template v-slot:label>
            Konfirmasi Kata Sandi Baru
          </template>
          <b-form-input
          type="password"
          v-model="pass.confirmPass"
            placeholder="Silahkan masukan lagi kata sandi Baru"
          ></b-form-input>
        </b-form-group>
        <div class="text-center">
          <button
            class="button-back"
            size="lg"
            type="button"
            @click="$bvModal.hide('bv-modal-gantipass')"
          >
            Kembali
          </button>
          <button
            class="ml-8 button-submit"
            size="lg"
            ref="kt_login_signin_submit"
            type="button"
             @click="submitChangePass()"
          >
            Ganti
          </button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
// import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Swal from "sweetalert2";
import { ModelSelect } from 'vue-search-select'
import ConstActivity from '../../../../act_constants'

export default {
  name: "KTQuickUser",
  components: {
      ModelSelect
  },
  data() {
    return {
      createMode: true,
      username: '',
      options: [],
      userType: '',
      user: { 
        profilUser: {
          email: "",
          file_foto: "",
          id: null,
          identitas: "",
          m_kesbangpol_id: null,
          nama_lengkap: "",
          no_telp: "",
          sys_user_id: null
        }
      },
      pass: {
        oldPass: '',
        newPass: '',
        confirmPass: ''
      },
      form: {
        nama_pengguna: '',
        nip: '',
        nik: '',
        email: '',
        no_hp: '',
        instansi: null,
        foto: null,
        url_login: process.env.VUE_APP_APPLICATION_BASE_URL + '/login',
        user_type: null,
      },
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
      loadUpload: false,
      idnya: null,
    };
  },
  mounted() {
    this.getProfile()
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    // this.username = JSON.parse(localStorage.getItem('user')).username
    // this.user = JSON.parse(localStorage.getItem('profile'))
    this.getOrmasList()
    
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    logout: function() {
      this.$store.dispatch("storeActivity", {
        "activity": ConstActivity.ACT_LOGOUT,
        "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
      });

      var role = JSON.parse(localStorage.getItem('user')).roles

      if(role.length > 0){
        if(role[0] === 'ormas') this.$router.push('/login')
        else this.$router.push('/polpum')
      } else {
        this.$router.push('/login')
      }
      localStorage.clear();
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    onSubmit(){

    },
    changeProfile(){

      if(this.form.user_type == 0 || this.form.user_type == null) this.form.user_type = 1
      this.form.user_type = Number(this.form.user_type)
      
      var body = {}
      var path = ''
      if(this.form.instansi == null){
        delete this.form.instansi
      }
      if(this.createMode) {
        path = 'submitUsers'
        body = this.form
      } else {
        path = 'submitPatchUsers'
        body = [this.form, this.idnya]
      }

      if(body[0].foto === null) body[0].foto = ''
      if(body[0].nik === null) body[0].nik = ''
        this.$store
        .dispatch(path, body)
        .then((res) => {

          if(!res.data.status) {

            var err = Object.values(res.data.error)
            var er = ''
            for(var i = 0; i < err.length; i++){
              er += err[i] + ','
            }
            er.slice(0,-1)
            Swal.fire(
                {
                  title: 'Gagal Mengubah Data',
                  icon: "warning",
                  text: er,
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  Swal.close();
                }
              );
              return false
          }
          var context = this
          context.getProfile()
          context.detailData()
          context.createMode = true
          Swal.fire(
                {
                  title: this.createMode ? "Data Berhasil Disimpan" : 'Data Berhasil Diubah',
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                
                  Swal.close();
                }
              );
        })
      
    },
    submitChangePass(){
      var body = {
          "oldPassword": this.pass.oldPass,
          "newPassword": this.pass.newPass,
          "confirmPassword": this.pass.confirmPass
        }
      
      this.$store
        .dispatch('changePasswordProfile', body)
        .then((res) => {

          if(!res.data.status) {

            var err = Object.values(res.data.error)
            var er = ''
            for(var i = 0; i < err.length; i++){
              er += err[i] + ','
            }
            er.slice(0,-1)
            Swal.fire(
                {
                  title: 'Gagal Mengubah Password',
                  icon: "warning",
                  text: er,
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                  Swal.close();
                }
              );
              return false
          }
          var context = this
          Swal.fire(
                {
                  title: "Password Baru Berhasil Disimpan",
                  icon: "success",
                  text: "",
                  confirmButtonColor: "#063A69",
                  confirmButtonText: "Tutup",
                  cancelButtonText: false
                },
                function() {
                context.createMode = false
                this.$bvModal.hide('bv-modal-gantipass')
                context.getProfile()
                context.detailData()
                  Swal.close();
                }
              );
        })
    },
    getProfile(){
      // if(!localStorage.getItem('profile')) return false
        this.$store
        .dispatch("getProfile").then((res)=>{
          // setTimeout(() => {
            this.$nextTick(()=>{
              this.username = res.data.data.username  
              this.$forceUpdate()
            })
            this.userType = res.data.data.roleUsers[0].role.role_code
          // }, 1500);
          
        })
        this.detailData()
        // this.username = JSON.parse(localStorage.getItem('user')).username
    },
    getOrmasList(){
      this.$store
        .dispatch("instansiList")
        .then((res) => {
           this.$nextTick(() => {
            this.listOrmas = res.data
            for(var i = 0; i < res.data.length; i++){
              this.options.push({value: res.data[i].id , text: res.data[i].nama})
            }
          })
          })
    },
    toSeeProfile(){
      // this.detailData()
      this.user = JSON.parse(localStorage.getItem('profile'))
      this.$bvModal.show('bv-modal-example')
    },
    toChangeProfile(){
      this.createMode = false
      this.detailData()
    },
    detailData(){
      if (!localStorage.getItem('profile')) return false
      this.idnya = JSON.parse(localStorage.getItem('profile')).id
      this.$store
      .dispatch("detailUsers", this.idnya)
      // .dispatch("getBankAccountOrganization", 4)
      .then((res) => {
        
        this.form = {
          "nama_pengguna": res.data.profilUser.nama_lengkap,
          "nip": res.data.username,
          "nik": res.data.profilUser.identitas,
          "email": res.data.profilUser.email,
          "no_hp": res.data.profilUser.no_telp,
          "instansi": res.data.profilUser.m_kesbangpol_id,
          "user_type": res.data.roleUsers[0].role.id,
          "url_login": "https://siormas.dev.layanan.go.id/login",
          "foto": res.data.profilUser.file_foto
        }
        // setTimeout(() => {
          // localStorage.setItem('profile', JSON.stringify(res.data))  
          this.user = JSON.parse(localStorage.getItem('profile'))
        // }, 1500);
        
        
      })
    },
    fileClicked(){
        this.$refs.fileInput.click()  
    },
    uploadFile(e){
      this.loadUpload = true
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.loadUpload = false
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadFileGeneral', formData).then((res) =>{

        if(res.data.status != false){
          this.form.foto = res.data.data.newFilePath,
          
              this.loadUpload = false
              Swal.fire({
                title: "Upload File Berhasil",
                text: '',
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              }).then(() => {
                this.$forceUpdate()
              })
        }
        this.$refs.fileInput.value=null;
      }).catch((err) =>{
        this.loadUpload = false
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.$refs.fileInput.value=null;
      })
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    }
  }
};
</script>
<style scoped>
.button-aktivasi {
  margin-top: 50px;
  background: #063a69;
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
  border-radius: 10px;
  padding: 16px 60px;
}
</style>
