<template>
  <div style="padding-top:15px;">
    <ul
      class="menu-nav"
      style="padding-bottom:0px !important;padding-top:0px !important"
      v-if="$route.name == 'formormas'"
    >
      <router-link
        :to="'/formdataormas'"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
        >
          <a
            :href="href"
            class="menu-link"
            @click="navigate"
          >
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Design/Layers.svg" />
            </span>
            <span class="menu-text pl-3 font-weight-bolder">Pengisian Data Ormas</span>
          </a>
        </li>
      </router-link>
    </ul>


    <!-- if not melengkapi data -->
    <ul
      v-else
      class="menu-nav"
      style="padding-bottom:0px !important;padding-top:0px !important"
      v-for="(item,index) in listMenu"
      :key="index"
    >

      <router-link
        :to="item.url"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-if="item.items.length == 0"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
          @click="collapseFalse()"
        >
          <a
            :href="href"
            class="menu-link"
            @click="navigate"
          >
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Design/Layers.svg" />
            </span>
            <span class="menu-text pl-3 font-weight-bolder">{{ item.label }}</span>
          </a>
        </li>
      </router-link>
      <li
        v-else
        aria-haspopup="true"
        data-menu-toggle="hover"
        :class="item.collapse ? 'menu-item menu-item-submenu menu-item-open' : 'menu-item menu-item-submenu'"
      >
        <!-- v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }" -->
        <a
          href="#"
          class="menu-link"
          @click.stop="collapseFunc(index)"
        >
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
          </span>
          <span class="menu-text pl-3 font-weight-bolder">{{item.label}}</span>
          <v-icon v-if="!item.collapse">mdi-chevron-down</v-icon>
          <v-icon v-if="item.collapse">mdi-chevron-up</v-icon>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li
              aria-haspopup="true"
              class="menu-item menu-item-parent"
            >
              <span class="menu-link">
                <span class="menu-text">{{item.label}}</span>
              </span>
            </li>
              <router-link
                v-for="(submenu,index2) in item.items"
                :key="index2"
                :to="submenu.url"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  v-if="submenu.items.length == 0"
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                >
                  <a
                    :href="href"
                    class="menu-link"
                    @click="navigate"
                  >
                    <span class="svg-icon svg-icon-lg mt-2">
                      <inline-svg src="media/svg/icons/Design/Flatten.svg" />
                    </span>
                    <span class="menu-text pl-3 font-weight-bolder">{{submenu.label}}</span>
                  </a>
                </li>

                <li
                  v-else
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  :class="submenu.collapse ? 'menu-item menu-item-submenu menu-item-open' : 'menu-item menu-item-submenu'"
                >
                  <!-- v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }" -->
                  <a
                    href="#"
                    class="menu-link"
                    @click.stop="collapseFunc2(index,index2)"
                  >
                    <span class="svg-icon svg-icon-lg mt-2">
                      <inline-svg src="media/svg/icons/Design/Flatten.svg" />
                    </span>
                    <span class="menu-text pl-3 font-weight-bolder">{{submenu.label}}</span>
                    <v-icon v-if="!submenu.collapse">mdi-chevron-down</v-icon>
                    <v-icon v-if="submenu.collapse">mdi-chevron-up</v-icon>
                  </a>
                  <div class="menu-submenu">
                    <span class="menu-arrow"></span>
                    <ul class="menu-subnav">
                      <li
                        aria-haspopup="true"
                        class="menu-item menu-item-parent"
                      >
                        <span class="menu-link">
                          <span class="menu-text">{{submenu.label}}</span>
                        </span>
                      </li>
                        <router-link
                          v-for="(subsubmenu,index) in submenu.items"
                          :key="index"
                          :to="subsubmenu.url"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                          >
                            <a
                              :href="href"
                              class="menu-link"
                              @click="navigate"
                            >
                              <span class="svg-icon svg-icon-lg mt-2">
                                <inline-svg src="media/svg/icons/Design/Mask.svg" />
                              </span>
                              <span class="menu-text pl-3 font-weight-bolder">{{subsubmenu.label}}</span>
                            </a>
                          </li>
                          

                        </router-link>
                    </ul>
                  </div>
                </li>
              </router-link>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "KTMenu",
  data() {
    return {
      infoOrmas: false,
      pelaporan: false,
      listMenu: null
    }
  },
  mounted(){
    
    let getList = JSON.parse(localStorage.getItem('user')).menus
    this.listMenu = getList
  },
  methods: {
    toggle(sec){
      if(sec == 'infoOrmas') this.infoOrmas = !this.infoOrmas
      if(sec == 'pelaporan') this.pelaporan = !this.pelaporan
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    collapseFunc(index){
      for(var i = 0; i < this.listMenu.length; i++) {
        if(i == index) {
          this.listMenu[i].collapse = !this.listMenu[i].collapse
        } else {
          this.listMenu[i].collapse = false
        }
      }
      var b = JSON.parse(localStorage.getItem('user'))
      b.menus = this.listMenu
      var a = JSON.stringify(b)
      localStorage.setItem('user', a)
    },

    collapseFunc2(index, index2){
      for(var i = 0; i < this.listMenu.length; i++) {
        if(i == index) {
          // this.listMenu[i].collapse = !this.listMenu[i].collapse
          for(var a = 0; a < this.listMenu[i].items.length; a++){
            if(a == index2) {
             this.listMenu[i].items[a].collapse = !this.listMenu[i].items[a].collapse
            }else {
              this.listMenu[i].items[a].collapse = false
            }
          }
        } else {
          this.listMenu[i].collapse = false
        }
      }
      var b = JSON.parse(localStorage.getItem('user'))
      b.menus = this.listMenu
      var c = JSON.stringify(b)
      localStorage.setItem('user', c)
    },

    collapseFalse(){
      for(var i = 0; i < this.listMenu.length; i++) {
        this.listMenu[i].collapse = false
      }
      var b = JSON.parse(localStorage.getItem('user'))
      b.menus = this.listMenu
      var a = JSON.stringify(b)
      localStorage.setItem('user', a)
    }
  }
};
</script>
