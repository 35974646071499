<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div class="container-fluid">
      <div class="row text-center">
        <div class="col-lg-12">
          <a
            href="https://polpum.kemendagri.go.id/"
            target="_blank"
            class="text-dark-75 text-hover-primary"
          >
            &#9400; Direktorat Jenderal Politik Dan Pemerintahan Umum
            {{ copyYear }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
  data() {
    return {
      copyYear: new Date().getFullYear(),
    };
  },
};
</script>
